import * as React from 'react'
import Layout from '../components/layout'

function ContactForm() {
  return(
    <form accept-charset="UTF-8" action="https://getform.io/f/beb5b280-616f-4639-8fbf-85f7acd6ae7e" method="POST" target="_blank">
    <div class="form-group">
      <label htmlFor="fullname">Your full name:</label>
      <input type="text" name="fullname" class="form-control" id="exampleInputName" required="required" />
    </div>
    <div class="form-group">
      <label htmlFor="email" required="required">Your email address:</label>
      <input type="email" name="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
    </div>
    <div class="form-group">
      <label htmlFor="message">Your message:</label>
      <textarea class="form-control" rows="5" name="message" id="message" placeholder="Hey, I wanted to talk to you about..." required="required"></textarea>
    </div>
    <input type="hidden" name="_website" style={{display: "none !important"}} />
    <button type="submit" class="btn btn-primary">Submit</button>
  </form>
  )
}

const ContactPage = () => {
  return (
    <Layout pageTitle="Contact">
    <div>
    <h1>Contact me</h1>
    <p>Interested in working with me on something? Want to request a copy of my resume? Want to just chat about web design or development? Use my contact form here to shoot me a quick message, or you can email me directly at <a href="mailto:drichtmyre@gmail.com"><strong>drichtmyre@gmail.com</strong></a></p>
    <ContactForm />

    </div>
    </Layout>
  )
}


export default ContactPage