import * as React from 'react'
import Navigation from './nav'
import { Helmet } from 'react-helmet'



const Layout = ({ pageTitle, children }) => {
  return (
    <main className="container-md p-0">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="row gx-0">
        <Navigation />
        <div className="main-content non-home col-lg-9 col-12">
          {children}
        </div>
      </div>
    </main>
  )
}

export default Layout